<template>
  <div class="content">
    <PageHeader title="Webhooks" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12">
                  <button
                    v-modal="{ target: 'webhook-modal' }"
                    class="btn btn-success m-width-50 btn-new"
                  >
                    <i class="icon dripicons-plus color-white"></i>{{$t('webhook-component.new-webhook')}}
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!webhooks.length && fetched"
                  class="text-center table-placeholder"
                >
                  <br />
                  <i class="fas fa-plug font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('webhook-component.none')}}</h5>
                </div>
                <table v-if="fetched" class="table">
                  <thead class="bg-light" v-if="webhooks.length">
                    <tr>
                      <th scope="col">{{$t('generic-str.type')}}</th>
                      <th scope="col">URL</th>
                      <th scope="col">{{$t('generic-str.status.lbl-active')}}</th>
                      <th scope="col">{{$t('generic-str.actions')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(webhook, i) in webhooks" :key="webhook.id">
                      <td scope="row" data-label="Tipo">
                        <span v-if="webhook.type == 'sms_dlr'"
                          >Callback - SMS DLR</span
                        >
                        <span v-if="webhook.type == 'email_dlr'"
                          >Callback - Email DLR</span
                        >
                        <span v-if="webhook.type == 'email_mo'"
                          >Callback - Email MO</span
                        >
                        <span v-if="webhook.type == 'optout'"
                          >Callback - Optout</span
                        >
                        <span v-if="webhook.type == 'blacklist'"
                          >Callback - Blacklist</span
                        >
                        <span v-else-if="webhook.type == 'sms_mo'"
                          >Callback - SMS MO</span
                        >
                        <span v-else-if="webhook.type == 'lookup'"
                          >Callback - Lookup</span
                        >
                        <span v-else-if="webhook.type == 'whatsapp_dlr'"
                          >Callback - WhatsApp DLR</span
                        >
                        <span v-else-if="webhook.type == 'whatsapp_mo'"
                          >Callback - WhatsApp MO</span
                        >
                      </td>
                      <td data-label="URL">{{ webhook.url }}</td>
                      <td data-label="Ativo">
                        <div
                          class="custom-control custom-checkbox custom-control-inline custom-control-inline-send"
                        >
                          <input
                            class="tgl tgl-light tgl-primary calendar-change"
                            :id="`schedule${i}`"
                            :checked="webhook.active"
                            type="checkbox"
                            name="schedule"
                            @change="toogle(webhook.id)"
                          />
                          <label class="tgl-btn" :for="`schedule${i}`"></label>
                        </div>
                      </td>
                      <td data-label>
                        <div>
                          <div class="dropdown">
                            <a
                              role="button"
                              class="dropdown-action"
                              :to="'#menu-dropdown-' + i"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="icon dripicons-gear text-success"></i>
                            </a>
                            <div
                              class="dropdown-menu dropdown-menu-right top-35"
                              :id="'menu-dropdown-' + i"
                              x-placement="bottom-end"
                              style
                            >
                              <a
                                class="dropdown-item"
                                v-modal="{
                                  target: 'edit-webhook-modal',
                                  data: webhook,
                                }"
                                >{{$t('generic-str.edit')}}</a
                              >
                              <a
                                class="dropdown-item"
                                @click="remove(webhook.id)"
                                >{{$t('generic-str.remove')}}</a
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <webhook-modal id="webhook-modal" @sent="fetch(form.page)"></webhook-modal>
    <update-webhook-modal
      id="edit-webhook-modal"
      @sent="fetch(form.page)"
    ></update-webhook-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import WebhookService from '@/services/webhook.service';
import PageHeader from '@/components/PageHeader.vue';
import WebhookModal from '@/components/webhooks/WebhookModal.vue';
import UpdateWebhookModal from '@/components/webhooks/UpdateWebhookModal.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'webhooks',
  components: {
    PageHeader,
    WebhookModal,
    UpdateWebhookModal,
    Pagination,
  },
  data() {
    return {
      fetched: false,
      webhooks: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      isSending: false,
      selectedwebhooks: [],
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      WebhookService.getWebhooks(this.form).then(
        (response) => {
          this.fetched = true;
          this.webhooks = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    toogle(id) {
      this.fetched = false;
      WebhookService.toggleWebhook(id).then(
        () => {
          this.fetch(this.form.page);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('webhook-component.remove.title'),
        text: this.$t('webhook-component.remove.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.fetched = false;
          WebhookService.removeWebhooks(id).then(
            () => {
              this.fetch(this.form.page);
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('webhook-component.remove.deleted'),
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.block-el {
  min-height: 300px;
}
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}
.btn-new {
  margin-bottom: 5px;
}
.color-white {
  color: #fff;
}
.form-search {
  float: right;
  width: 100%;
}
.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}
.form-search button i {
  font-size: 16px;
}
.w-100 {
  width: 100% !important;
}
</style>
